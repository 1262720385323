<template>
  <div class="main">
    <div class="Section0">
      <p align="center" class="MsoNormal title">“易鑫金融”隐私政策</p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">版本发布日期：</font>2022年7月11日
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">版本生效日期：</font>2022年7月21日
        </span>
      </p>
      <p class="MsoNormal"
        style="text-indent: 21pt; text-align: left; line-height: 16.5pt; background: rgb(255, 255, 255);"></p>
      <p class="MsoNormal">
        <span class="span2">
          北京序禄信息科技有限公司（注册地址：北京市海淀区学清路18号6幢2层6218室，以下称为“我们”）是“易鑫金融APP”的运营者，同时在服务运营过程中我们也会与关联公司联合运营，我们非常重视用户（以下称为“您”）的隐私和个人信息保护。您在使用我们的服务以及服务所包括的各种业务功能（以下称“我们的服务与/或产品”
          ）时，我们可能会收集和使用您的相关信息。我们希望通过《隐私政策》（“本隐私政策”）向您说明我们在您使用我们的服务与/或产品时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新和保护这些信息的方式。
        </span>
      </p>
      <p class="MsoNormal">
        <b><span class="span1">
            <font face="宋体">本隐私政策与您所使用的我们的服务与</font>
            /或产品息息相关，希望您在使用我们的服务与/或产品前仔细阅读并确认您已经充分理解本政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的服务与/或产品，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。
          </span></b><b></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">本隐私政策将帮忙您了解以下内容：</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">一、我们如何收集和使用您的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">二、我们如何使用</font>Cookie及同类技术
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">三、我们如何共享、转让、公开披露您的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">四、我们如何存储和保护您的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">五、您如何管理您的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">六、对第三方责任声明及SDK目录</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">七、我们如何保护未成年人的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">八、本隐私政策如何更新</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">九、如何联系我们</font>
        </span>
      </p>
      <p class="MsoNormal"></p>
      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">一、我们如何收集和使用您的个人信息</font>
          </span></b><b></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">
            个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括：基本信息（包括姓名、性别、出生年月日、住址、电话号码、职业）；个人身份信息（包括身份证）；网络身份标识信息（包括账号、账户名、头像、账户昵称、密码）；个人财产信息（包括银行卡信息、房产信息、信用记录、信贷记录、月收入、交易和消费记录）；个人上网记录（包括网站浏览记录、软件使用记录、点击记录）；个人常用设备信息（包括设备名称、设备型号、设备品牌、操作系统类型、
          </font>
          IDFA、MAC地址、IP地址、WIFI名称、IMEI、IMSI等在内的描述个人常用设备基本情况的信息）；个人位置信息（包括精准定位信息、经纬度）；其他个人信息（包括摄像头、相册、车辆信息、车架号码、发动机号、车型、车价、车辆登记注册日期。
        </span>
      </p>
      <p class="MsoNormal">
        <b><u><span class="span5">
              <font face="宋体">
                个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括：个人身份信息；网络身份识别信息、个人财产信息、个人位置信息、生物识别信息。
              </font>
            </span></u></b><b><u><span class="span5"></span></u></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">我们收集您的个人信息的最终目的是为了向您提供更好的我们的服务与</font>
          /或产品，优化并丰富您的用户体验，这些个人信息是能够单独或者与其他信息结合识别您的个人身份的信息。以上个人信息均是您自愿提供。您有权拒绝提供，但如果您拒绝提供某些个人信息，您将可能无法使用我们的服务与/或产品，或者可能对您使用我们的服务与/或产品造成一定的影响。
        </span>
      </p>
      <p class="22"
        style="margin-left: 36pt; text-indent: -36pt; text-align: left; line-height: 16.5pt; background: rgb(255, 255, 255);">
        <span class="span2"><span>（一）<span></span></span></span><span class="span2">
          <font face="宋体">我们如何收集您的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">我们仅会出于以下目的，收集和使用您的个人信息：</font>
        </span>
      </p>
      <p class="MsoNormal">
        <b><span class="span1">1、</span></b><span class="span2">
          <font face="宋体">用户注册</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">您在使用我们的服务与</font>/或产品时，需要注册一个账户成为我们的用户。
        </span><b><span class="span1">
            <font face="宋体">当您注册时，您需要向我们提供您本人的手机号码，我们将通过发送短信验证码的方式来验证您身份是否有效。
            </font>
          </span></b><span class="span2">
          <font face="宋体">
            根据相关法律法规的规定，您需通过身份基本信息多重交叉验证后方可使用我们的部分服务（例如汽车金融产品申请、汽车保险服务等），如果您使用此类服务，则可能需提供更多信息（例如：姓名、身份证件号码）以完成身份的多重交叉校验。同时，为了验证您提供信息的准确性和完整性，我们会与合法留存您的信息的国家机关、金融机构、企事业单位进行核对；如在验证核对过程中我们需要向前述验证机构收集您的信息，我们会依照法律法规的规定要求相关验证机构说明其个人信息来源，并对其个人信息来源的合法性进行确认。您注册成功后，可以对您的个人信息（头像、昵称、性别、出生年月、省份城市、现居住地）进行完善和维护。
          </font>
        </span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">2、注册成功后，我们提供的服务与/或产品，包括车辆信息浏览/搜索/筛选、汽车金融产品申请、汽车保险服务、客服和售后服务，如您想使用上述功能，您需要向我们提供或授权我们收集相应的个人信息。如您拒绝提供部分服务与/或产品所需的个人信息，则可能无法使用这部分服务与/或产品，但不影响您正常使用我们其他的服务与/或产品。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>1）汽车信息浏览/搜索/筛选
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">为了让您快速地找到您所需要的车辆、匹配汽车金融产品，</span>
        <b><span class="span1">
            <font face="宋体">我们可能会收集您使用我们的服务与</font>
            /或产品的个人常用设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、浏览器类型来为您提供车辆信息展示的最优方式。
          </span></b>
        <span class="span2">我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">
            您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。</font>
        </span><b><span class="span1">
            <font face="宋体">
              请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；
            </font>
          </span></b>
        <span
          class="span2">只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>2）汽车金融产品申请
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          当您申请汽车金融产品时，我们需要收集您的个人位置信息，
        </span>
        <b><span class="span1">
            <font face="宋体">
              以便我们为您推荐您所在城市支持的产品及服务，便于您快速进行选择；
            </font>
          </span></b>
        <span class="span2">
          若您拒绝我们的请求，也可以通过您手动选择城市的方式查看车型信息、金融产品信息。
        </span>
        <b><span class="span1">
            <font face="宋体">
              我们还需要收集您的姓名、手机号、验证码、职业、信用记录、信贷记录、社保公积金、月收入范围，以便完成订单提交。
            </font>
          </span></b>
      </p>
      <p class="MsoNormal">
        <b><span class="span2">
            <font face="宋体">
              上述信息属于您的个人敏感信息，如您拒绝提供该信息，
            </font>
          </span></b>
        <span class="span2">
          上述信息属于您的个人敏感信息，如您拒绝提供该信息，仅会使您无法使用上述功能，但不影响您正常使用易鑫金融的其他功能。
        </span>
        <b><span class="span1">
            <font face="宋体">
              您提供的上述信息，将在您使用本服务期间持续授权我们使用，您可以通过与客服联系了解相关信息。在您注销账户时，我们将停止使用并删除。
            </font>
          </span></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>3）汽车保险服务
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          您可以通过汽车保险服务查看汽车保险种类和相关信息，并为您的汽车购买保险。
        </span>
        <b><span class="span1">
            <font face="宋体">
              在此过程中，我们会收集您的姓名、身份证号码、手机号、车牌号、车架号码、发动机号、车型、车价、车辆登记注册日期等信息，以便完成汽车保险的订单提交。
            </font>
          </span></b>
        <span class="span2">
          您申请成功后，我们的关联公司将会为您提供相关的服务，您也可以登录个人账户查看申请的订单信息。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>4）专鑫融产品
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          您可以通过专鑫融产品获取和车辆相关的服务（包括但不限于汽车商业保险融资、延保融资、精品加装通知、购置税融资、保养服务包融资）。
        </span>
        <b><span class="span1">
            <font face="宋体">
              在此过程中，我们会收集您的APP定位、设备信息（手机型号、手机品牌、设备类型、系统版本、系统名称、设备名称、IDFA、IMEI、IMSI、MAC、IP、WiFi名称）、个人身份信息、银行卡信息、摄像头，
            </font>
          </span></b>
        <span class="span2">
          同时需要您签署线上《大数据授权协议》和《百行征信授权协议》，以便更好的完成相关服务。您申请成功后，我们的第三方合作公司将会为您提供相关的服务。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>5）客服与售后功能
        </span>
      </p>
      <p class="MsoNormal">
        <b><span class="span1">
            <font face="宋体">为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的基本信息、网络身份标识信息核验您的身份。</font>
          </span></b>
        <span class="span2">
          当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">3、</span><b><span class="span1">
            <font face="宋体">改进我们的服务与</font>/或产品所必须的功能
          </span></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          我们可能会收集您的订单信息、浏览信息、您的关注车型（您可以在账户设置中选择）进行数据分析以形成用户画像，用来将您感兴趣的车辆或服务信息展示给您；或在您搜索时向您展示您可能希望找到的车辆信息。
        </span>
        <span class="span1">
          我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。</span>

        <span class="span2">
          对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。
        </span>
      </p>
      <p class="MsoNormal">
        <b><span class="span1">4、保障交易安全所必须的功能</span></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">为提高您使用我们的服务与</font>
          /或产品时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，
        </span>
        <span class="span1">
          我们可能会通过了解您的浏览信息、订单信息、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息对于我们系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">5、您可选择是否授权我们收集和使用您的个人信息的情形：</span>
      </p>
      <p class="MsoNormal">
        <span class="span1">（1）基于位置信息的个性化推荐功能：</span>
        <span class="span2">我们会收集您的</span>
        <span class="span1">位置信息</span>
        <span
          class="span2">（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您推荐您所在区域可以购买的汽车车型或服务。比如向您推荐离您最近的可选汽车经销商、门店等信息。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span1">（2）通知功能：</span>
        <span class="span2">我们将会将我们的优惠信息、活动信息等推送给您，便于您及时掌握该信息。您可以选择是否接收我们推送给您的消息。</span>
      </p>
      <p class="MsoNormal">
        <span class="span1">（3）摄像头：</span>
        <span class="span2">您可以选择对帐号进行实名认证和对头像进行设置，在认证和设置过程中，需要您使用摄像头上传身份证照片或其他影像材料。拒绝授权后上述功能无法使用。</span>
      </p>
      <p class="MsoNormal">
        <span class="span1">（4）相册：</span>
        <span class="span2">在实名认证和头像设置过程中，需要你使用相册中的图片。我们获得图片信息后，加密后存储于数据库中。拒绝授权后上述功能将无法使用。</span>
      </p>
      <p class="MsoNormal">
        <span class="span1">（5）网络通信：</span>
        <span class="span2">用于与服务端进行通讯。拒绝授权后，我们的服务与/或产品所有功能无法使用，但系统后台会保存您使用设备的网络信息，包括IP、端口信息。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          上述功能可能需要您在使用您的设备中向我们开启您的地理位置（位置信息）、通知、摄像头、相册、网络通信的访问权限，以实现这些功能所涉及的信息的收集和使用。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些信息来实现上述功能，如您取消了这些权限，则我们将不再继续收集收集和使用您的这些信息，也无法为您提供上述这些授权所对应的功能。
        </span>
      </p>
      <p class="MsoNormal">
        <u><b><span class="span5">6、我们在向您提供其他服务与/或产品时，会另行向您说明信息收集的范围与目的，并征得您的同意后方收集所必要的您的个人信息。</span></b>
          <span class="span2">我们会按照本隐私政策以及相应的约定收集、使用、存储、对位提供及保护您的个人信息。</span></u>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（二）我们如何使用您的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          我们为了遵守国家法律法规及监管要求，以及向您提供服务与/或产品及提升服务质量，保障您的账户安全，我们会在以下情形中使用您的信息：
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">1、我们会根据本隐私政策的约定并为实现我们的服务与/或产品功能对所收集的您的个人信息进行使用。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">2、为了使您知晓使用我们服务与/产品的状态，我们会向您发送服务提醒。</span><span
          class="span1">您可以通过手机系统设置中的通知设置，关闭服务提醒，也可以通过通知设置重新开启服务提醒。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">3、为了保障服务与/或产品的稳定性和安全性，</span><span
          class="span1">我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">4、根据法律法规或监管要求向相关部门进行报告。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">5、邀请您参加我们服务与/或产品有关的客户调研。</span>
      </p>
      <p class="MsoNormal">
        <b><u><span
              class="span5">6、我们会对您的信息进行综合统计、分析加工，以便为您提供更加准确、流畅、便捷的服务与/或产品，或帮助我们评估、改善、设计服务与/或产品和运营活动等。我们可能根据前述信息向您提供营销活动通知、商业性电子信息或您可能感兴趣的广告，如果您不希望接收此类信息，您可按照我们提示的方法选择退订。</span></u></b><b><u><span
              class="span5"></span></u></b>
      </p>
      <p class="MsoNormal">
        <b><u><span
              class="span5">7、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span></u></b>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">8、我们会对我们的服务与/或产品使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的服务与/或产品的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">9、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">10、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</span>
      </p>
      <p class="MsoNormal">
        <b><u><span class="span5">
              <font face="宋体">（三）您充分知晓，以下情形中，我们收集、使用和对外提供您的个人信息无需征得您的授权同意：
              </font>
            </span></u></b><b><u><span class="span5"></span></u></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">1、与国家安全、国防安全有关的</span><span class="span2">
          <font face="宋体">。</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">2、与公共安全、公共卫生、重大公共利益有关的。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">3、与犯罪侦查、起诉、审判和判决执行等有关的，或公安、检察院、法院、工商行政管理局等有权机关要求提供的。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">5、所收集的个人信息是您自行向社会公众公开的。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">7、根据您的要求签订和履行合同所必需的。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">8、用于维护所提供的服务与/或产品的安全稳定运行所必需的，例如发现、处置服务与/或产品的故障。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">9、为合法的新闻报道所必需的。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">11、法律法规规定的其他情形。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（四）我们从第三方获得您个人信息的情形</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">
            我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您在我们这里的账户绑定，使您可以通过第三方账户直接登录并使用我们的服务与 </font>
          /或产品。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
        </span>
      </p>
      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">二、我们如何使用</font>Cookie和同类技术
          </span></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（一）为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为
          </font>
          Cookies的小数据文件，指定给您的Cookies是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的偏好等数据进而根据您的偏好提供服务与/或产品，帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（二）我们不会将</font>
          Cookies用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，您也可以清除浏览器内保存的所有Cookies，移动端退出登录后也可以清除所有Cookies。但如果您这么做，您可能需要在每一次访问我们服务与/产品时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
        </span>
      </p>
      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">三、我们如何共享、转让、公开披露您的个人信息</font>
          </span></b><b></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（一）共享</font>
        </span>
      </p>
      <p class="MsoNormal">
        <b><span class="span1">
            <font face="宋体">我们不会与我们以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</font>
          </span></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">1、事先获得您明确的同意或授权。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下对外共享。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">3、只有共享您的信息，才能实现我们的服务与/或产品的核心功能或提供您需要的服务。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">4、您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</span>
      </p>
      <p class="MsoNormal">
        <b><span
            class="span1">5、与我们的关联方共享：我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</span></b><b><span
            class="span1"></span></b>
      </p>
      <p class="MsoNormal">
        <b><span
            class="span1">6、我们会对合作方获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与合作方约定严格的数据保护措施，令其按照我们的委托目的、服务条款、本隐私协议以及其他任何相关的保密和安全措施来处理个人信息。</span></b><b><span
            class="span1"></span></b>
      </p>
      <p class="MsoNormal">
        <b><span
            class="span1">7、与我们的合作伙伴共享：包括您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span></b><span
          class="span2">我们的合作伙伴包括以下类型：</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>1）车辆或服务的供应商。
        </span><b><u><span class="span5">
              <font face="宋体">我们可能会将您的个人信息共享给支持易鑫集团相关服务功能的第三方。</font>
            </span></u></b><span class="span2">
          <font face="宋体">
            这些支持包括为您网上选车提供基础设施技术服务、申请金融产品服务、数据处理等。我们共享这些信息的目的是可以实现我们服务或产品的核心</font>
          ——线上选车、选择金融产品功能。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>2）第三方商家，如汽车经销商、汽车保险服务的服务提供者。
        </span><b><u><span class="span5">
              <font face="宋体">
                我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买车辆或服务的需求，并促使其可以完成后续的售后服务。</font>
            </span></u></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>
          3）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们服务与/或产品的用户群提供促销推广的服务。
        </span><b><u><span class="span5">
              <font face="宋体">我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（
              </font>
              “委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。
            </span></u></b><b><u><span class="span5"></span></u></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">8、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">9、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护我们、您或其他用户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（二）转让</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">1、事先获得您明确的同意或授权。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行转让。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供。</span>
      </p>
      <p class="MsoNormal">
        <b><u><span
              class="span5">4、根据法律法规或商业惯例，在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</span></u></b>
      </p>
      <p class="MsoNormal">
        <span class="span1">
          <font face="宋体">（三）公开披露</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span1">
          <font face="宋体">我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span1">1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息。</span>
      </p>
      <p class="MsoNormal">
        <span
          class="span1">2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</span>
      </p>
      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">四、我们如何存储和保护您的个人信息</font>
          </span></b><b></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（一）存储</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">1、我们在中华人民共和国境内收集和产生的个人信息将全被存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。</span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">2、我们仅在法律法规要求的期限内，以及为实现您对我们服务与/或产品使用之目的所必须的时限内保留您的个人信息。当您注销账户或我们的服务与/或产品停止运营时，我们会对您的个人信息采取相应的删除、匿名化措施。</span><b><span
            class="span1"></span></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（二）保护</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">1、</span><span class="span2">
          <font face="宋体">
            我们已经采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来尽力防止您的个人信息遭到未经授权的访问使用、修改</font>,损坏或丢失。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>1）我们采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>
          2）在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>3）我们采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>4）我们采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>
          5）我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>6）我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">2、我们存储您个人数据的底层云技术通过了公安部安全等级保护三级认证。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">3、</span><b><span class="span1">
            <font face="宋体">
              我们仅允许有必要知晓这些信息的易鑫集团及易鑫集团关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与易鑫集团的合作关系。
            </font>
          </span></b>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">4、如我们提供的全部或部分服务与/或产品停止运营，我们将通过公告等形式向您进行告知，同时停止对您个人信息的收集等操作，保护您的个人信息安全。如因技术故障、网络攻击、自然灾害及事故、人为因素等各种原因造成全部或部分我们的服务与/或产品中断，我们将采取应急处理和恢复措施予以应对，尽快恢复服务。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">5、</span><b><span class="span1">
            <font face="宋体">我们会采取一切合理可行的措施，确保未收集无关的个人信息。</font>
          </span></b><span class="span2">
          <font face="宋体">我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">6、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">7、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">8、在通过我们与第三方进行网上服务或产品的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。</span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">9、为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了相关制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程，并建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、电话、等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">10、如果您对我们的个人信息保护有任何疑问，或您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本隐私政策约定的联系方式联络我们，以便我们采取相应措施。</span>
      </p>
      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">五、您如何管理您的个人信息</font>
          </span></b><b></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">按照中国相关的法律法规和监管规定，我们保障您对自己的个人信息可行使以下权利：</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（一）访问、更正及更新您的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">除法律法规规定外，您有权随时访问、更正及更新您的个人信息，具体包括：</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span1">1、首页—我的—头像，查阅、更新、完善您的个人资料，</span><span
          class="span2">包括头像、昵称、性别、出生年月、省份城市、现居住地；点击“设置”进入设置—账户密码、修改密码。</span>
      </p>
      <p class="MsoNormal">
        <span class="span1">2、对于已成交的订单，您可以关注“易鑫车主服务公众号”查看期初签署的合同、还款计划
          操作信息变更（自营及部分资方订单）、申请提前结清（自营及部分资方订单）、还款（自营及部分资方订单）、申请理赔等。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">3、对于您在使用我们的服务与/或产品过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（二）删除您的个人信息</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">1、您有权根据自身需要直接清除或删除个人信息。具体操作路径为：首页—我的—头像—删除。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">2、您有权根据自身需要直接清除或删除订单信息，具体操作路径为：首页—
          我的订单—订单列表—订单详情—取消订单。如订单已经转入线下销售服务，则您无法直接删除订单信息，可通过页面提示的内容联系客服进行删除。如订单已经完成，但申请的金融产品在还款期内或有相关纠纷的，则不能删除订单，待上述事宜结束后可以进行删除。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">3、</span><b><span class="span1">
            <font face="宋体">在以下情形中，您可以向我们提出删除个人信息的请求：</font>
          </span></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>1）如果我们处理个人信息的行为违反法律法规。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>2）如果我们收集、使用您的个人信息，却未征得您的同意。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>3）如果我们处理个人信息的行为违反了与您的约定。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>4）如果我们终止服务及运营。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>5）如果您不再使用我们的服务与/或产品，或您注销了账号。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">
            若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
          </font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（三）改变您授权同意的范围或撤回您的授权</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">1、您可以通过关闭设备功能、拨打客服联系电话等方式改变部分您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权</span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">2、请您注意，您自主注销账户的同时，将视为您撤回了对本隐私政策的同意。当您撤回同意后，我们将不再处理相应的个人信息。但是您撤回同意的决定，不影响此前基于您的授权而开展的个人信息处理。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（四）您注销自身的账户</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">1、在满足如下条件的情况下</span>
        <span class="span1">您有权注销此前注册的账户，您注销的行为是不可逆行为，一旦您注销，我们将不再收集您的个人信息，并删除有关您的个人信息。</span>
        <span class="span2">法律法规或监管机构对个人信息存储时间另有规定的除外。</span>
        <span class="span1">具体删除路径为：我的—设置—账户安全—注销。</span>
      </p>
      <p class="MsoNormal">
        <span class="span1">
          （1）账号处于正常使用状态且在1年内无任何被限制记录；
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span1">
          （2）本账号所涉交易业务已全部完成，且不存在任何纠纷；
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span1">
          （3）账号不涉及任何纠纷，包括投诉、举报或被投诉、被举报等情形。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">2、您可以本隐私政策中的联系方式与我们联系，要求我们对您的账户进行删除。</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（五）响应您的请求</font>
        </span>
      </p>
      <p class="MsoNormal">
        <span
          class="span2">1、如果您无法通过上述方式访问、更正、更新或删除您的个人信息，或您需要访问、更正、更新或删除您在使用我们服务与/或产品时所产生的其他个人信息，或您认为我们存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本隐私政策中的联系方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的15日内答复您的请求。</span><b><u><span
              class="span5">
              <font face="宋体">
                对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
              </font>
            </span></u></b><b><u><span class="span5"></span></u></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">2、在以下情形中，按照法律法规要求，我们将无法响应您的请求：</span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>1）与国家安全、国防安全有关的。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>2）与公共安全、公共卫生、重大公共利益有关的。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>3）与犯罪侦查、起诉和审判等有关的。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>4）有充分证据表明您存在主观恶意或滥用权利的。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（</font>6）涉及我们商业秘密的。
        </span>
      </p>
      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">六、对第三方责任声明及SDK目录</font>
          </span></b><b></b>
      </p>

      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（一）</font>
        </span><b><u><span class="span5">
              请您注意，您通过易鑫金融APP接入的第三方服务和由易鑫金融APP处接收您的个人信息的第三方可能有自己的隐私权保护政策；当您查看第三方创建的网页或者使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie及技术，这些Cookie及技术不受我们的控制，且他们的使用不受本政策的约束。我们会努力去要求这些主体对您的个人信息采取保护措施，建议您与他们联系以获得关于他们的隐私政策的详细情况。如您发现这些第三方创建的网页或第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。易鑫金融APP第三方SDK目录如下：<br />
            </span></u></b><b><u><span class="span5">
              第三方公司名称：上海游昆信息技术有限公司<br />
              收集个人信息用途/目的：社交分享，第三方登录<br />
              收集个人信息类型：系统运行信息、网络状态信息、iOS广告标识符（IDFA）、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID、地理位置<br />
              第三方隐私权政策连接：<a href=" javascript:void(0);" @click="pushSDK">www.mob.com/about/policy</a>
            </span></u></b>
        <b><u><span class="span5"></span></u></b>
      </p>

      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">七、我们如何保护未成年人的个人信息</font>
          </span></b><b></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（一）我们非常重视对未成年人个人信息的保护。我们的服务与</font>
          /或产品主要面向成人，若您是18周岁以下的未成年人，在使用我们的服务与/或产品时，必须在您父母或其他法定监护人的监护下进行。我们将根据国家相关法律法规的规定保护未成年人的个人信息的保密性和安全性。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          <font face="宋体">（二）</font>
        </span><b><u><span class="span5">
              <font face="宋体">
                如您为未成年人，请您的父母或其他法定监护人阅读本隐私政策，并在征得您父母或其他法定监护人同意的前提下使用我们的服务与</font>
              /或产品，向我们提供您的个人信息。
            </span></u></b><span class="span2">
          <font face="宋体">
            对于经您父母或法定监护人同意而收集的您的个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您合法权益所所必要的情况下使用或公开披露此信息。
          </font>
        </span><b><u><span class="span5">
              <font face="宋体">如您的父母或其他法定监护人不同意您按照本隐私政策使用我们的服务与</font>
              /或产品，向我们提供个人信息，请您立即终止使用并及时通知我们，以便我们采取相应的措施。
            </span></u></b><b><u><span class="span5"></span></u></b>
      </p>
      <p class="MsoNormal">
        <u><span style="font-family: 宋体; color: rgb(0, 0, 0); text-decoration: underline; font-size: 10.5pt;">
            <font face="宋体">
              （三）如您为未成年人的父母或法定监护人，当您对您所监护的未成年人的个人信息处理存在疑问时，请通过本隐私政策中的联系方式联系我们。</font>
          </span></u>
      </p>
      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">八、</font>
            <font face="宋体">本隐私政策如何更新</font>
          </span></b><b></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          （一）为给您提供更好的服务以及随着我们业务的发展，我们将对本隐私政策及相关规则不时的进行修改和更新。
        </span>
        <span class="span1">
          我们在更新本隐私政策后，我们会及时提示您更新的情况，您选择继续使用我们的服务与/产品时，我们会将新的隐私政策重新取得您的授权，同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          （二）您应不时关注相关公告，提示信息及协议、规则内容的修订和更新。您知悉并确认，如您不同意修改和更新后的内容，可能会影响您对该部分的使用权限，如您再准备使用但功能受限，您可以在重新同意/授权后，取得对应服务的使用权限。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          （三）修改和更新的隐私政策，未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利，不会更改您的权限设置，扩大收集使用您的个人信息。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          （四）本《隐私协议》查看路径：“我的-设置-隐私政策”。
        </span>
      </p>
      <p class="MsoNormal p-cus">
        <b><span class="span1">
            <font face="宋体">九、如何联系我们</font>
          </span></b>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          （一）如您对本隐私政策或您个人信息的相关事宜有任何疑问、问题、意见、建议或投诉，您可随时拨打我们</span><span class="span1">客服电话4000598598（工作日9:00 -
          18:00)</span><span class="span2">进行咨询或反映。受理您的问题后，我们会及时、妥善处理。
        </span>
      </p>
      <p class="MsoNormal">
        <span class="span2">
          （二）我们设立了信息安全管理委员会，您可通过发送邮件至负责人邮箱shuy@yxqiche.com与其联系，一般情况下，我们将在15日内给予答复。
        </span>
      </p>
      <p class="MsoNormal">
        <span
          style="font-family: Calibri; color: rgb(51, 51, 51); font-size: 10.5pt; background: rgb(243, 250, 255);"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy",
  methods: {
    pushSDK() {
      this.$router.push({
        name: "privacySDK"
      });
    }
  }
};
</script>
<style scoped>
.main {
  width: 100%;
  padding: 0 0.1rem;
  background: rgb(255, 255, 255);
}

.MsoNormal {
  text-align: left;
  line-height: 20pt;
  margin-bottom: 0.1rem;
  background: rgb(255, 255, 255);
  overflow: hidden;
}

.title {
  text-align: center;
  padding: 0.2rem 0;
  font-size: 10.5pt;
}

.p-cus {
  margin-top: 15pt;
  margin-bottom: 7.5pt;
  text-align: left;
  line-height: 18.75pt;
  background: rgb(255, 255, 255);
}

.span1 {
  font-family: "宋体";
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 10.5pt;
}

.span2 {
  font-family: "宋体";
  color: rgb(0, 0, 0);
  font-size: 10.5pt;
}

.span3 {
  font-family: "宋体";
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 10.5pt;
}

.span4 {
  font-family: "宋体";
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 10.5pt;
}

.span5 {
  font-family: "宋体";
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 10.5pt;
}
</style>
